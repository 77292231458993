

// Import Bootstrap SCSS




$theme-colors: (
  "danger": #FF0000,
  "dark":#212529,
  "secondary":#495057,
  "light":white
);


@import '../../node_modules/bootstrap/scss/bootstrap.scss';



button.danger-radio-red{border:2px solid #FF0000!important;padding: 0;}
button.danger-radio-dark{border:2px solid #000000!important;padding: 0;}
.radio-timg-sec{display: flex;justify-content: space-between;align-items: center;padding: 1rem;}
.radiotext{font-size: 1rem;font-weight: 600;color: #000;}
.radio-timg-sec img{height:37px;}

.danger-radio-dark .radio-timg-sec img{fill: #000 !important; filter: invert(0%) sepia(0%) saturate(0%);}

.step-main .MuiStepLabel-iconContainer.Mui-active, .step-main .MuiStepLabel-iconContainer.Mui-disabled{padding-right:0!important;}

.step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 1.5em!important;
  height: 1.5em!important;}
  .step-main .MuiStepLabel-iconContainer.Mui-disabled svg circle{fill: #000;}
  .step-main .MuiStepLabel-iconContainer svg text{font-size: 0.8rem; font-weight: bold;}

  .step-main .MuiStepLabel-iconContainer.Mui-completed{
    padding-right:0!important;
  } 
  .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 1.5em!important;
    height: 1.5em!important;}
    .map-secstep{margin: 0;width:100%; height:90%; }

.big-title{font-size: 1.5rem;line-height: 2rem; font-weight: 600;margin:0 auto 20px auto;white-space:preserve;text-align: center;}
.big-title1{font-size: 1.5rem;line-height: 2rem; font-weight: 600;margin:20px auto 0px auto;text-align: center;}


    .button-wrapper .btn{margin-top: 0;}
    .inputbox{border-radius: 6px;border:2px solid #000;}
    .inputbox:focus-visible{box-shadow: none;
      outline: 0;}
      .first-button-wrapper{display: flex;justify-content: space-between;}
      .voter-box-grid-main{max-width: 49rem;margin: 0 auto 20px;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.voter-box-grid{display: flex;flex-direction: row;gap:1rem;}
.voter-box{width: 25%;display: flex;flex-direction: row;}
.voter-box-grid-main2{max-width: 49rem;margin: 0 auto 20px;display: flex;justify-content: center;align-items: center;flex-direction: column;}
.voter-box-grid2{display: flex;flex-direction: row;gap:1rem;}
.voter-box2{width: 15%;display: flex;flex-direction: row;}
.voter-box-btn{ box-shadow: 0 0px 20px rgba(0,0,0,0.2);display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: center;padding: 10px;}
.voter-box-btn img{ fill: #000 !important; filter: invert(0%) sepia(0%) saturate(0%);}
.voter-box-btn.voter-box-btn-red img{ fill: none !important;filter: none;}
    .voter-box-btn-red, .voter-box-btn-red2{border:2px solid #FF0000!important;}
    .voter-box-btn-black, .voter-box-btn-black2{border:2px solid #000000!important;}
    .voter-box-text, .voter-box-text2{font-size: 0.8rem;font-weight: 500; line-height: 1rem;display: flex;flex-direction: column;margin: 10px 0 0 0;}
    .voter-box-text.text-dark, .voter-box-text2.text-dark{color:#000000!important;}

    .voter-box-btn2{ box-shadow: 0 0px 20px rgba(0,0,0,0.2);display: flex;width: 100%;flex-direction: column;align-items: center;justify-content: flex-start;padding: 10px;}
    .fase-img{height: 60px;}

    .voter-box-btn2 img{ fill: #000 !important; filter: invert(0%) sepia(0%) saturate(0%);}
    .voter-box-btn2.voter-box-btn-red2 img{ fill: none !important;filter: none;}

    .bonne-sec{max-width: 49rem;margin: 0 auto;}
    .bonne-sec .two-col-row{display:flex;flex-direction: row;gap:1rem;}
    .bonne-sec .two-col{display:flex;flex-direction: column;width:49%;}
    .bonne-sec .bonne-btn-sec{margin: 20px 0;}
    .bonne-sec .label-col{display: flex;flex-direction: column;text-align: center;}

    .question-btn{padding:5px;background: transparent;margin: 0;display: flex;}
   .question-modal{}
   .question-modal .que-header{display: flex;justify-content: flex-end;}
   .question-modal .que-header .close-icon{width:25px;height: 25px;border-radius: 100px;border:0px;background:#000;display: flex;
    align-items: center;
    justify-content: center;}
   .question-modal .content-wrapper{color:#000;font-size: 0.9rem;line-height: 1.3rem;max-height: 500px;
    overflow: hidden;overflow-y: scroll;}
   .question-modal .content-wrapper ul.qm-list{color:#000;margin:0 0 15px 20px; padding: 0;}
   .question-modal .content-wrapper ul.qm-list li{color:#000;font-size: 0.9rem;}
   .question-modal .content-wrapper ul.qm-list ul{color:#000;margin:0 0 10px 20px; padding: 0;}

   .contacte-form .label-text{font-size: 0.9rem;line-height: 1.3rem;font-weight: 600;color:#000;max-width: 338px;margin:15px auto;display: flex;text-align: center;}

   .contacte-form{max-width: 49rem;margin: 0 auto;gap: 1rem;display: flex;flex-direction: column;}
   .contacte-form .two-col-row{display:flex;flex-direction: row;gap:1rem;}
   .contacte-form .two-col{display:flex;flex-direction: column;width:49%;}
   .contacte-form .one-col{display:flex;flex-direction: column;width:100%;}
   .contacte-form .label-col{display: flex;flex-direction: column;text-align: center;}
   .contacte-form .label-col .title{font-size: 1.5rem;line-height: 2rem; font-weight: 600;}
   .droup-select{}
   .checkbox-sec{display: flex;flex-direction: column;gap: 0.6rem;}
   .checkbox-sec .checkbox-inr{display: flex;align-items: start;}
   .checkbox-sec .custom-checkbox{display: flex;width: 15px;height: 15px;margin: 4px 10px 0 0;}
   .checkbox-sec label{font-size: 0.7rem;}
    // fill: var(--white-color) !important;
    // filter: invert(.9);
    .sui-button-wrapper{display: flex;
  justify-content: space-between;
  margin: 15px 0;}


  .btn-flat {
    background: #FF0000;
    color: white;
  }
    
  .btn-flat:hover {
    background:var(--bs-gray-dark);
    color: white;
  }
  .btn-xxl {
    padding: 1rem 1.5rem;
    font-size: 1.5rem;
  }

  .btn-secondary{background:var(--bs-gray-dark);border-color: var(--bs-gray-dark);}
  .btn-secondary:hover{ background: #FF0000;border-color: #FF0000;}
  .form-control:disabled {
    background-color:#fff;
    opacity: 1;
  }

  .custom-table .t-head{
    background-color: transparent !important;
  }
  
  .custom-table .t-head-item{
    color: white !important;
    text-align: center !important;
    background: #FF0000;
  }
    .custom-table .t-head-item .table-label{font-weight: 700;}

  .custom-table{
    border-collapse: separate !important;
  }
  .custom-table tr:nth-child(odd) {
    background-color: #c1c1c1;
    text-align: center;
  }
  .custom-table tr:nth-child(even){
    background-color: #f2f2f2;
    text-align: center;
  }
  
  // .custom-table td:nth-child(1) {
  //   background-color: transparent !important;
  //   /* color: white !important; */
  // }
  
  .custom-table .t-non-background{
    background-color: white !important;
    
  }
  
  .custom-table .table-label{
    font-size: 14px;
    text-align: center;
    max-height: fit-content;
    display: flex;flex-direction: column;font-weight:500;
  }

  .d-auto-none{
    display: none !important;
  }
  
  th .text-wrapper{display: flex;flex-direction: column;justify-content: flex-end;text-align: right;padding: 0 6px 0 0;}
  th .text-wrapper .table-label{text-align: right;}
  
  .custom-table .big-label{
  font-size: 14px !important;font-weight: 600 !important;
  }
  
  td .text-wrapper{display: none;}
  


      @media (max-width:768px) {
        .first-button-wrapper{flex-direction: column;}
        .voter-box-grid2{flex-wrap: wrap;justify-content: center;}
        .voter-box2{width:30.7%;}

      }



            @media (max-width:576px) {
              .voter-box2 {
                width: 48%;
              }

              .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 0.8em!important;
                height: 0.8em!important;}
                .step-main .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root, .step-main .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root{width: 0.8em!important;
                  height: 0.8em!important;}

                  .step-main .MuiStepLabel-iconContainer.Mui-completed .MuiSvgIcon-root{width: 0.8em!important;
                    height: 0.8em!important;}
      
              .voter-box-grid {
                flex-wrap: wrap;
                justify-content: center;
              }
      
              .map-secstep {
                width: 100%;
                height: 90%;
              }
      
              .big-title {
                font-size: 1rem !important;
                line-height: 1rem !important
              }

              .custom-table{
                border-collapse: collapse !important;
              }
              .custom-table tr,td{
                border: 1px solid black;
              }
      
              
      
              .custom-table .table-label {
                font-weight: 700 !important;
              }
      
              .voter-box {
                width: 48%;
              }
      
              @media screen and (max-width:400px) {
                .bd-highlight {
                  height: 60vh !important;
                }
      
              }
      
              .bonne-sec .two-col-row {
                flex-direction: column;
                gap: 0.6rem;
              }
      
              .bonne-sec .two-col {
                flex-direction: column;
                width: 100%;
              }
      
              .contacte-form .two-col-row {
                flex-direction: column;
                gap: 0.6rem;
              }
      
              .contacte-form .two-col {
                flex-direction: column;
                width: 100%;
              }
      
              // .table-wrapper{overflow: hidden;overflow-x: scroll;}
              // .custom-table {width: 570px;}
      
              .custom-table th {
                display: none;
              }


              .custom-table tr:not(.d-auto-none) {
               background-color: transparent !important;
              }
       
              .d-auto-none{
                display: table-row !important;
                background-color: #c1c1c1 !important;
                text-align: center;
              }
      
              .custom-table td {
                padding-top: 6px;
                padding-bottom: 6px;
              }
      
              td .text-wrapper {
                display: flex;
                flex-direction: column;
              }
            }

    .info{
      font-size: 12px;
    }

      @media (max-width:475px) {
        .sui-button-wrapper{flex-direction: column;}
        .sui-button-wrapper .button-wrapper{margin: 5px 0;}
        .sui-button-wrapper .button-wrapper button{width: 100%;}
      
        .voter-box2{width:47%;}
        .voter-box{width:47%;}
        .inputbox::placeholder{
          font-size: 11.5px
        }
      }

  
      