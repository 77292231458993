body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.architectural-proximity-container {
  /* max-width: 600px; */
  /* margin: 0 auto; */

  /* border: 1px solid #ccc;
  border-radius: 50px; */
  border: none !important;
  border-radius: 0px !important;
  /* height:1300px !important; */
  /* background-color: #d9d9d9; */
   /* Set background color here */
}

label {
  display: block;
  margin-bottom: 10px;
}

input,
select {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}

/* Additional styles for Step 3 container */
div[title='Step 3'] {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 50px;
  background-color: #d9d9d9;
}

/* Additional styles for Step 3 inputs */
.form-control {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="number"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  box-sizing: border-box;
}
/* s */

.architectural-proximity-container {
  /* max-width: 600px; */
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  /* background-color: #f4f4f4; */
}

label {
  display: block;
  margin-bottom: 5px;
}

select.form-control,
input.form-control {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  box-sizing: border-box;
}

/* Add any other specific styles for Step 2 if needed */

/* Additional styles for Step 3 container */

button {
  display: block;
  /* margin-top: 10px; */
  color: #fff;
  border: none;
  border-radius: 6px !important;
  cursor: pointer;
}


#ex1Slider .slider-selection {
	background: #BABABA !important;
}


